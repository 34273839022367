
/*@font-face {*/
/*	font-family: "demo_Sans";*/
/*	src: url("../fonts/Beeline_Sans-Regular.woff") format("woff");*/
/*	src: url("../fonts/Beeline_Sans-Regular.woff2") format("woff2");*/
/*	src: url("../fonts/Beeline_Sans-Regular.eot") format("embedded-opentype");*/
/*	src: url("../fonts/Beeline_Sans-Regular.ttf") format("truetype");*/
/*	font-style: normal;*/
/*	font-weight: 400;*/
/*}*/
/*@font-face {*/
/*	font-family: "Beeline_Sans";*/
/*	src: url("../fonts/Beeline_Sans-Bold.woff") format("woff");*/
/*	src: url("../fonts/Beeline_Sans-Bold.woff2") format("woff2");*/
/*	src: url("../fonts/Beeline_Sans-Bold.eot") format("embedded-opentype");*/
/*	src: url("../fonts/Beeline_Sans-Bold.ttf") format("truetype");*/
/*	font-style: auto;*/
/*	font-weight: 600;*/
/*}*/

/*@font-face {*/
/*	font-family: "onest";*/
/*	src: url("../fonts/Onest-VariableFont_wght.ttf") format("truetype");*/
/*	font-style: auto;*/
/*	font-weight: 400;*/
/*}*/
/*@font-face {*/
/*	font-family: "onest";*/
/*	src: url("../fonts/Onest-VariableFont_wght.ttf") format("truetype");*/
/*	font-style: auto;*/
/*	font-weight: 600;*/
/*}*/
@font-face {
	font-family: "roboto";
	src: url("../fonts/Roboto-VariableFont_wdth,wght.ttf") format("truetype");
	font-style: auto;
	font-weight: 400;
}
@font-face {
	font-family: "roboto";
	src: url("../fonts/Roboto-VariableFont_wdth,wght.ttf") format("truetype");
	font-style: auto;
	font-weight: 600;
}
