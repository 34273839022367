






















































































































































































































































































































































@import "@/assets/css/project-variables";

.dashboard{
  position: relative;

  &-grid-layout {
    width: 100%;
    position: relative;
    min-height: 340px;
  }

  &-grid-item.dashboard-grid-item-hovered{
    z-index: 10;
  }

  // Сетка
  &-grid-layout.edit-mode::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 48) calc(30px + 10px);
    background-image:
      linear-gradient(
        to right,
        #e0e0e0 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    position: absolute;
    background-repeat: repeat;
    margin:5px;
  }

  .widget-container {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: $--border-radius-base;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    &-overlay{
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(204, 204, 204, 0.97);
    }

    .widget-actions-container {
      position: fixed;
      z-index: 3;
      display: flex;
      padding: 5px;
      background-color: #e1e1e1;
      border-radius: $--border-radius-base;
    }

    .widget-body{
      width: 100%;
      height: 100%;
    }

  }

}


