<template>
  <div>
    <div class="dashboard" v-loading="loading">
      <grid-layout
        class="dashboard-grid-layout"
        :class="{'edit-mode': editMode}"
        :layout="userWidgets"
        :col-num="colNum"
        :row-height="30"
        :is-draggable="editMode && $canAndRulesPass(dashboard, $permissions.HOME_DASHBOARD.WIDGET_MANAGE)"
        :is-resizable="editMode && $canAndRulesPass(dashboard, $permissions.HOME_DASHBOARD.WIDGET_MANAGE)"
        :is-bounded="false"
        :vertical-compact="false"
        :use-css-transforms="true"
        :prevent-collision="true"
        :margin="[10,10]"
      >
        <grid-item
          v-for="widget in userWidgets"
          class="dashboard-grid-item"
          :class="{
            'dashboard-grid-item-hovered': (currentHover===widget.i || currentDeleting===widget.i)
          }"
          :key="widget.i"
          :x="widget.x"
          :y="widget.y"
          :w="widget.w"
          :h="widget.h"
          :i="widget.i"
          :min-w="1"
          :min-h="1"
          @resized="widgetResized"
          @moved="widgetMoved"
        >
          <div
            v-if="!widget.settings.disabled || editMode"
            class="widget-container"
            :style="{
              'box-shadow': '0 0 '+widget.settings.shadowSize+'px '+widget.settings.shadowColor,
              'border': widget.settings.borderSize+'px solid '+widget.settings.borderColor,
            }"
            @mouseover="widgetHovered(widget.i)"
            @mouseout="widgetUnhovered"
          >
            <div v-if="widget.settings.disabled" class="widget-container-overlay"></div>

            <div
              v-show="editMode && $canAndRulesPass(dashboard, $permissions.HOME_DASHBOARD.WIDGET_MANAGE) && (currentHover===widget.i || currentDeleting===widget.i)"
              :style="{
                top: widget.y===0 ? 'unset' : '-40px',
                bottom: widget.y===0 ? '-44px' : 'unset',
                right: widget.x > colNum-8 ? '0' : 'unset',
              }"
              class="widget-actions-container"
            >
              <el-button
                size="mini"
                icon="fas fa-cog"
                @click="openSettings(widget)"
              >
                Настройки
              </el-button>

              <el-tooltip
                style="margin-right: 10px"
                content="Клонировать"
                placement="top"
                :open-delay="500"
              >
                <el-button
                  size="mini"
                  icon="fas fa-copy"
                  @click="cloneWidget(widget)"
                >
                </el-button>
              </el-tooltip>

              <el-popover
                placement="bottom"
                width="250"
                trigger="click"
              >
                <div>
                  <div style="margin-bottom: 10px">Выберите дашборд для перемещения:</div>
                  <div
                    v-for="dashboardToShow in dashboards"
                    :key="dashboardToShow.id"
                    style="margin-bottom: 5px"
                  >
                    <el-button
                      :disabled="dashboardToShow.id===dashboard.id"
                      style="width: 100%; text-wrap: auto"
                      size="mini"
                      @click="moveWidgetToDashboardId(widget, dashboardToShow.id)"
                    >
                      {{ dashboardToShow.id===dashboard.id ? 'Текущий' : dashboardToShow.settings.name}}
                    </el-button>
                  </div>
                </div>

                <el-tooltip
                  slot="reference"
                  content="Переместить на дашборд"
                  placement="top"
                  :open-delay="500"
                >
                  <el-button
                    size="mini"
                    icon="fas fa-share-square"
                  >
                  </el-button>
                </el-tooltip>
              </el-popover>

              <el-popconfirm
                confirm-button-text='Да'
                cancel-button-text='нет'
                icon="el-icon-question"
                title="Вы уверены, что хотите удалить виджет?"
                @confirm="removeWidget(widget.i)"
              >
                <el-button
                  slot="reference"
                  style="margin-left: 10px"
                  type="danger"
                  size="mini"
                  icon="fas fa-trash"
                  @click="currentDeleting=widget.i"
                ></el-button>
              </el-popconfirm>

            </div>




            <div
              v-if="widget.settings.name"
              class="widget-header"
              :style="{
                'color': widget.settings.headerTextColor || 'black',
                'font-size': (widget.settings.headerTextSize || '16') + 'px',
                'text-align': widget.settings.headerTextAlign || 'center',
                'padding': widget.settings.headerPadding+'px',
                'background-color': widget.settings.headerBackgroundColor,
                'border-bottom': widget.settings.headerBottomBorderSize+'px solid '+widget.settings.headerBottomBorderColor,
              }"
            >
              {{widget.settings.name}}
            </div>

            <div class="widget-body">
              <component
                :is="widget.viewTemplate"
                :key="widget.renderKey"
                :ref="'widget_'+widget.id"
                :edit-mode="editMode"
                :widget="widget"
                :settings="widget.settings"
                :data="widget.data"
              >
              </component>
            </div>



          </div>
        </grid-item>
      </grid-layout>
    </div>

    <WidgetSettingsDialog
      ref="widgetSettingsModal"
      @saved="widgetSaved"
    />
  </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import UserWidget from "@/components/home/dashboard/widgets/UserWidgetClass.js";
import WidgetSettingsDialog from "@/components/home/dashboard/widgetSettings/WidgetSettingsDialog.vue";


export default {
  name: 'HomepageDashboard',
  components: {
    WidgetSettingsDialog,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props:{
    dashboard: {type: Object, required: true},
    dashboards: {type: Array, required: true},
    editMode: {type: Boolean, default: false},
  },
  data() {
    return {
      loading: false,
      colNum: 48,

      hoverTimeout: null,
      currentHover: null,
      currentDeleting: null,

      userWidgets: [],
    };
  },
  mounted() {
    this.loadWidgets();
  },
  methods: {
    loadWidgets(){
      this.loading = true;
      this.userWidgets = [];
      this.$api.homeDashboard.getDashboardWidgets({
        dashboard_id: this.dashboard.id,
      })
        .then((data) => {
          data.widgets.forEach(async widget => {
            const newWidget =  new UserWidget(widget);
            await newWidget.loadViewTemplate();
            this.userWidgets.push(newWidget);
          })
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    async addWidget(widgetType) {
      const widgetConfig = this.$companyConfiguration.home.getWidgetConfig(widgetType);

      const newWidget = new UserWidget();
      newWidget.dashboard_id = this.dashboard.id;
      newWidget.type = widgetType;
      newWidget.x = 0;
      newWidget.y = this.getLowestPosition();

      newWidget.save()
        .then(async data => {
          const newWidget = new UserWidget(data.widget);
          await newWidget.loadViewTemplate();
          this.userWidgets.push(newWidget);
        })
    },

    cloneWidget(widget){
      const clonedWidget = new UserWidget(widget);
      clonedWidget.setId(null);
      clonedWidget.x = 0;
      clonedWidget.y = this.getLowestPosition();
      clonedWidget.save()
        .then(async data => {
          clonedWidget.setId(data.widget.id);
          await clonedWidget.loadViewTemplate();
          this.userWidgets.push(clonedWidget);
        })
    },

    moveWidgetToDashboardId(widget, dashboardId){
      widget.dashboard_id = dashboardId;
      widget.save()
        .then(data => {
          this.userWidgets = this.userWidgets.filter(w => w.id !== widget.id);
        })
    },

    widgetMoved(id, newX, newY){
      const changedWidget = this.userWidgets.find(w => w.id === id);
      if( !changedWidget ){
        return;
      }
      changedWidget.save();
    },
    widgetResized(id, newH, newW, newHPx, newWPx){
      const changedWidget = this.userWidgets.find(w => w.id === id);
      if( !changedWidget ){
        return;
      }
      changedWidget.save();

      this.$refs['widget_'+id][0].eventWidgetResized();
    },

    widgetSaved(widget) {
      widget.renderKey++;
    },

    removeWidget(id) {
      this.$api.homeDashboard.deleteUserWidget({
        id: id,
      })
        .then(data => {
          this.userWidgets = this.userWidgets.filter(w => w.id !== id);
        })
        .catch(() => {
          this.$notify.error({
            title: 'Ошибка',
            message: 'Не смог удалить виджет'
          });
        })

    },


    openSettings(widget) {
      this.$refs.widgetSettingsModal.open(widget);
    },

    getLowestPosition() {
      if (this.userWidgets.length === 0) {
        return 0;
      }
      return Math.max(...this.userWidgets.map(item => item.y + item.h));
    },

    widgetHovered(id){
      clearTimeout(this.hoverTimeout);
      this.currentHover = id;
      this.currentDeleting = this.currentDeleting===id ? id : null;
    },
    widgetUnhovered(){
      this.hoverTimeout = setTimeout(()=>{
        this.currentHover = null;
      }, 300)
    }

  }
};
</script>

<style>
.vue-grid-item.vue-draggable-dragging {
  background-color: rgba(0, 0, 0, 0.1);
}
.vue-grid-item.vue-grid-placeholder {
  background: rgba(0, 0, 0, 0.5);
  border: 2px dashed #aaa;
}
</style>

<style lang="scss">
@import "@/assets/css/project-variables";

.dashboard{
  position: relative;

  &-grid-layout {
    width: 100%;
    position: relative;
    min-height: 340px;
  }

  &-grid-item.dashboard-grid-item-hovered{
    z-index: 10;
  }

  // Сетка
  &-grid-layout.edit-mode::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 48) calc(30px + 10px);
    background-image:
      linear-gradient(
        to right,
        #e0e0e0 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    position: absolute;
    background-repeat: repeat;
    margin:5px;
  }

  .widget-container {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: $--border-radius-base;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    &-overlay{
      position: absolute;
      z-index: 20;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(204, 204, 204, 0.97);
    }

    .widget-actions-container {
      position: fixed;
      z-index: 3;
      display: flex;
      padding: 5px;
      background-color: #e1e1e1;
      border-radius: $--border-radius-base;
    }

    .widget-body{
      width: 100%;
      height: 100%;
    }

  }

}


</style>`