







































































































































































































@import "@/assets/css/project-variables";
.homepage{
  position: relative;

  &-toolbox{
    position: absolute;
    top: -28px;
    right: 0;
    z-index: 2;
  }

  &-tabs{
    &.hidden-header .el-tabs__header{
      display: none;
    }

    .el-tabs__content{
      overflow: inherit;
    }
  }

}

